$(function () {
    var navigationList = $('#pageNavigation-list');
    var subNavigation = $('#pageNavigation-sub');

    if ($('.hasSubNav').length) {
        var hoverTimeout;
        $('.pageNavigation-item').hover(function () { //hover
            if ($(this).hasClass('hasSubNav')) { //if item has subMenu show navigation
                if (!navigationList.hasClass('active')) { //if navigation is not open set timer to delay and try open
                    clearTimeout(hoverTimeout);
                    var $this = $(this);
                    hoverTimeout = setTimeout(function () {
                        showSubNav($this);
                    }, 300);
                } else { //if navigation is open change submenu
                    showSubNav($(this));
                }
            } else { //if item has not submenu hide navigation
                hideSubNav();
            }
        }, function () {
            if ($(this).hasClass('hasSubNav')) { //if item has subMenu
                if (!navigationList.hasClass('active')) { //if navigation is not open but hover is out clear timer to disable navigation to open
                    clearTimeout(hoverTimeout);
                }
            }
        });

        // hide navigation on hover on header
        $('.page-header').hover(function () {
            hideSubNav();
        });

        // hide navigation on hover on nav overlay
        $('.navOverlay').hover(function () {
            if (navigationList.hasClass('active')) {
                hideSubNav();
            }
        });

        // change submenu list preview image
        $('.pageNavigation-subNav-list-items a').hover(function () {
            var target = $(this).data('target');
            $('#' + target).parent().find('img').hide();
            $('#' + target).show();
        });

        // show navigation or change sub navigation
        function showSubNav(obj) {
            if (!navigationList.hasClass('active')) { // show navigation
                navigationList.addClass('active');
                subNavigation.show();
                showNavOverlay();
            } else {
                $('.hasSubNav').removeClass('active'); // hide last active sub navigation
            }
            // open new sub navigation
            obj.addClass('active');
            var target = obj.data('target');
            subNavigation.attr('data-activeSub', target);
        }

        // hide navigation
        function hideSubNav() {
            $('.hasSubNav').removeClass('active');
            navigationList.removeClass('active');
            subNavigation.hide();
            hideNavOverlay();
        }
    }
});