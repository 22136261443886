$(function () {
    if ($('#productDetail-gallery__big-360').length) {
        var id = $('#productDetail-gallery__big-360').attr('data-360-id');
        var lang = $('#productDetail-gallery__big-360').attr('data-360-lang');
        inject_orbitvu(
            'productDetail-gallery__big-360',
            'assets/js/libs/orbitvu/orbitvuer12.swf',
            'assets/js/libs/orbitvu/expressInstall.swf',
            {
                ovus_folder: 'data/360foto/' + id,
                content2: 'yes',
                width: "350",
                height: "400",
                lang: lang,
                teaser: 'autorotate',
                html5_hint: 'no',
                transition: 'no',
                style: '3',
                report_bugs: 'no',
                partial_load: 'yes',
                force_html5: 'yes',
                fullscreen_enabled: 'no'
            });
    }

    if ($('#productDetail-gallery__modal-preview-360').length) {
        var id = $('#productDetail-gallery__modal-preview-360').attr('data-360-id');
        var lang = $('#productDetail-gallery__modal-preview-360').attr('data-360-lang');
        var size = $('#productDetail-gallery__modal-preview').height();
        inject_orbitvu(
            'productDetail-gallery__modal-preview-360',
            'assets/js/libs/orbitvu/orbitvuer12.swf',
            'assets/js/libs/orbitvu/expressInstall.swf',
            {
                ovus_folder: 'data/360foto/' + id,
                content2: 'yes',
                width: size,
                height: size,
                lang: lang,
                teaser: 'autorotate',
                html5_hint: 'no',
                transition: 'no',
                style: '1',
                report_bugs: 'no',
                partial_load: 'yes',
                force_html5: 'yes',
                fullscreen_enabled: 'yes'
            });
    }
});