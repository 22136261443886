function showOverlay() {
    $('.overlay').stop().fadeIn(250);
}

function hideOverlay() {
    $('.overlay').stop().fadeOut(250);
}

function showNavOverlay() {
    $('.navOverlay').stop().fadeIn(250);
}

function hideNavOverlay() {
    $('.navOverlay').stop().fadeOut(250);
}