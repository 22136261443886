// shopDetail__gallery__carousel >>
$(function () {
    if ($('#shopDetail__gallery__carousel').length) {
        $('#shopDetail__gallery__carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: true,
            infinite: true,
            initialSlide: 0,
            centerMode: false,
            lazyLoad: 'ondemand',
            prevArrow: $('.shopDetail__gallery__carousel-prev'),
            nextArrow: $('.shopDetail__gallery__carousel-next')
        });
    }
});
// << shopDetail__gallery__carousel
