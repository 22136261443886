// carouselBox >>
$(function () {
  if ($('.carouselBox__list').length) {
    var carouselBox = $('.carouselBox__list').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      lazyLoad: 'ondemand',
      appendArrows: false,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 10000
    });

    carouselBox.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $('.carouselBox__navigation li[data-id=' + currentSlide + ']').removeClass('is-active');
      $('.carouselBox__navigation li[data-id=' + nextSlide + ']').addClass('is-active');
    })

    $('.carouselBox__navigation li a').on('click', function (e) {
      e.preventDefault();
      var id = $(this).parent('li').data('id');
      carouselBox.slick('slickGoTo', id);
    })
  }
});
// << carouselBox
