// productDetail-gallery__carousel >>
$(function () {
    if ($('#productDetail-gallery__carousel').length) {
        $('#productDetail-gallery__carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: true,
            infinite: true,
            initialSlide: 1,
            centerMode: true,
            lazyLoad: 'ondemand',
            prevArrow: $('.productDetail-gallery__carousel-prev'),
            nextArrow: $('.productDetail-gallery__carousel-next')
        });
    }
});
// << productDetail-gallery__carousel