// brandsCarousel >>
$(function () {
  if ($('.brandsCarousel__list').length) {
    // lazy init
    lazyInit($('.brandsCarousel__list').get(0), () => {
      $('.brandsCarousel__list').slick({
        slidesToShow: 7,
        slidesToScroll: 6,
        variableWidth: true,
        infinite: true,
        lazyLoad: 'ondemand',
        prevArrow: '.brandsCarousel__prev',
        nextArrow: '.brandsCarousel__next'
      });
    });
  }
});
// << brandsCarousel
