$(function () {

    if ($('#productDetail-gallery__modal').length) {

        var productDetail_gallery__list = $('#productDetail-gallery__modal-list');
        var productDetail_gallery__preview = $('#productDetail-gallery__modal-preview-img');
        var productDetail_gallery__prev = $('#productDetail-gallery__modal-preview-prev');
        var productDetail_gallery__next = $('#productDetail-gallery__modal-preview-next');
        var productDetail_gallery__modal = $('#productDetail-gallery__modal');

        productDetail_gallery__modal.on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            if (button.attr('data-galleryId')) {
                productDetail_gallery__showImage(button.attr('data-galleryId'));
            }
        });

        function productDetail_gallery__showImage(id) {
            var item = productDetail_gallery__getListItem(id);
            if (item) {
                if (item.attr('data-type') == 'image') {
                    var src = item.children('img').attr('src');
                    productDetail_gallery__preview.attr('src', src);
                    $('#productDetail-gallery__modal-preview-360').hide();
                    $('#productDetail-gallery__modal-preview-img').show();
                }
                if (item.attr('data-type') == '360') {
                    $('#productDetail-gallery__modal-preview-360').show();
                    $('#productDetail-gallery__modal-preview-img').hide();
                }
                $('#productDetail-gallery__modal-list .productDetail-gallery__modal-list-item').removeClass('active');
                productDetail_gallery__setCurrentItem(id);
                item.addClass('active');
            }
        }

        function productDetail_gallery__scrollToItem(item) {
            productDetail_gallery__list.scrollTop(item.position().top);
        }

        function productDetail_gallery__getCurrentItem() {
            return productDetail_gallery__list.attr('activeId');
        }

        function productDetail_gallery__getListItem(id) {
            return productDetail_gallery__list.find('.productDetail-gallery__modal-list-item[data-galleryId=' + id + ']');
        }

        function productDetail_gallery__setCurrentItem(id) {
            productDetail_gallery__list.attr('activeId', id);
        }

        function productDetail_gallery__showPrev() {
            var currentId = productDetail_gallery__getCurrentItem();
            var currentItem = productDetail_gallery__getListItem(currentId);

            var showItem = currentItem.prev();
            if (!showItem.length) {
                showItem = productDetail_gallery__list.find('.productDetail-gallery__modal-list-item').last();
            }

            productDetail_gallery__showImage(showItem.attr('data-galleryId'));
        }

        function productDetail_gallery__showNext() {
            var currentId = productDetail_gallery__getCurrentItem();
            var currentItem = productDetail_gallery__getListItem(currentId);

            var showItem = currentItem.next();
            if (!showItem.length) {
                showItem = productDetail_gallery__list.find('.productDetail-gallery__modal-list-item').first();
            }

            productDetail_gallery__showImage(showItem.attr('data-galleryId'));
        }

        productDetail_gallery__prev.on('click', function () {
            productDetail_gallery__showPrev();
            return false;
        });

        productDetail_gallery__next.on('click', function () {
            productDetail_gallery__showNext();
            return false;
        });

        $(document).keydown(function (e) {
            if (productDetail_gallery__modal.hasClass('in')) {
                switch (e.key) {
                    case "ArrowLeft":
                        productDetail_gallery__showPrev();
                        e.preventDefault();
                        break;
                    case "ArrowRight":
                        productDetail_gallery__showNext();
                        e.preventDefault();
                        break;
                    case "ArrowUp":
                        productDetail_gallery__showPrev();
                        e.preventDefault();
                        break;
                    case "ArrowDown":
                        productDetail_gallery__showNext();
                        e.preventDefault();
                        break;
                }
            }
        });

        $('.productDetail-gallery__modal-list-item').on('click', function () {
            productDetail_gallery__showImage($(this).attr('data-galleryId'));
            return false;
        });
    }
});