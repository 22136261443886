$(function () {
  $('.searchShop__modal__shop-link').on('click', function (e) {
    e.preventDefault();

    var $details = $(this).next('.searchShop__modal__shop-details');
    var $parent = $(this).parent('li');

    if ($parent.hasClass('is-active')) {
      $parent.removeClass('is-active');
      $details.slideUp();
    } else {
      $parent.addClass('is-active');
      $details.slideDown();

      $parent.siblings().each(function () {
        var $details = $(this).children('.searchShop__modal__shop-details');
        $(this).removeClass('is-active');
        $details.slideUp();
      })
    }
  });
})
