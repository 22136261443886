$(document).ready(function () {

    // articleNavigation >>
    if ($('#articleNavigation').length) {
        var elm = $('#articleNavigation');
        $(window).on('scroll load resize', function () {
            var height = $(window).scrollTop();
            var headerHeight = $('.page-container').offset().top;
            var elmSize = elm.height();
            var iconOffset = 15;

            var iconPosition = height - headerHeight;
            var min = headerHeight;
            if (height >= min) {
                elm.css({
                    position: 'absolute',
                    top: iconPosition
                })
            } else {
                elm.removeAttr('style');
            }
        })
    }
    // << articleNavigation
    // articleNavigation >>
    if ($('#articleDetail-share').length) {
        var elm = $('#articleDetail-share');
        $(window).on('scroll load resize', function () {
            var height = $(window).scrollTop();
            var headerHeight = $('.articleDetail-content').offset().top;
            var elmSize = elm.height();
            var iconOffset = 35;

            var iconPosition = height - headerHeight + 15;
            var min = headerHeight + iconOffset;
            if (height >= min) {
                elm.css({
                    position: 'absolute',
                    top: iconPosition
                })
            } else {
                elm.removeAttr('style');
            }
        })
    }
    // << articleNavigation
    // alertsBox >>
    if ($('#alertsBox').length) {
        var elm = $('#alertsBox');
        $(window).on('scroll load resize', function () {
            var height = $(window).scrollTop();
            var headerHeight = $('#header').height();
            var min = headerHeight;

            if (height >= min) {
                elm.addClass('fixed');
            } else {
                elm.removeClass('fixed');
            }
        })
    }
    // << alertsBox
});