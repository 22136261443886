function checkToTop() {
    var size = 50;

    var s = $(window).scrollTop();
    if (s > size) {
        $('body').addClass('showToTop')
    } else {
        $('body').removeClass('showToTop')
    }

    var windowWidth = $(window).width();
    var borderWidth = (windowWidth-1200) / 2;
    var minToTopWidth = 90 + 20 + 50; // width + margin right + margin left

    if(borderWidth > minToTopWidth){
        $('#toTop').css({
            right:20,
            left: 'auto'
        })
    }else{
        var w = windowWidth - borderWidth + 50;
        if(w < 1265){
            var w = 1265;
        }
        $('#toTop').css({
            right:'auto',
            left: w
        })
    }
}

$(window).on('scroll load resize', function () {
    checkToTop();
});
