// Control login navigation - for anchors out of navigation

$(function () {
    if ($('#loginNavigation').length) {
        $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
            var activeTab = 'loginNavigation-' + $(this).attr('aria-controls');
            $('#loginNavigation li').each(function () {
                if ($(this).attr('id') != activeTab) {
                    $(this).removeClass('active');
                } else {
                    $(this).addClass('active');
                }
            })
        })
    }
});