// infoLine >>
$(function () {
    // close line >>

    $('.infoLine .close').on('click', function () {
        $(this).closest('.infoLine').slideUp(150);
        return false;
    });

    // << close line
});
// << infoLine