// This fix params height on windows and add secondary scrollbar
$(function () {
    if ($('#compare__box').length) {
        intiCompareScrollBar()
    }
});

function intiCompareScrollBar() {
    var scrollBar = getScrollbarWidth();
    var box = $('#compare__box');
    var params = $('#compare__box-params');
    var paramsHeight = box.height() - (scrollBar * 2);
    var items = $('#compare__box-items');
    var itemsFake = $('#compare__box-items--fake');
    var fakeHeight = 10;
    var scrollWidth = 0;

    if (items.length) {
        if (items.find('.compare__box-item').length > 0) {
            scrollWidth = items.get(0).scrollWidth;
        }
    }

    if (items.find('.compare__box-item').length < 4) {
        fakeHeight = 0;
        scrollWidth = 0;
        items.addClass('hiddenOverflow');
        items.scrollLeft(0);
    } else {
        items.removeClass('hiddenOverflow');
    }

    itemsFake.children('div').width(scrollWidth);

    if (scrollBar > 0) {
        fakeHeight = scrollBar + 1;
    }

    itemsFake.height(fakeHeight);
    params.css({
        marginTop: fakeHeight,
        height: paramsHeight
    });

    itemsFake.scroll(function () {
        items.scrollLeft(itemsFake.scrollLeft());
    });
    items.scroll(function () {
        itemsFake.scrollLeft(items.scrollLeft());
    });
}