// Control tabs

$(function () {
    $('a[data-showTab]').on('shown.bs.tab', function () {
        var nav = $(this).attr('data-nav');
        var active = $(this).attr('data-tab');

        $('#' + nav).find('a').each(function () {
            if ($(this).attr('href') != '#' + active) {
                $(this).parent('li').removeClass('active');
            } else {
                $(this).parent('li').addClass('active');
            }
        });

        if($(this).attr('data-scrollToTab')){
            scrollWin($('#' + nav), 500, 0);
        }
    })
});