$(function () {
    $('#competition__form').on('click', function () {
        $('#competition__content').collapse('show');
        $('#competition__box-reload').fadeIn();
    });

    $('#competition__rules').on('click', function () {
        $('#competition__rules-content').addClass('isActive');
    });

    $('#competition__rules').on('click', function () {
        $('#competition__rules-content').addClass('isActive');
    });

    $('#competitionOpenGift, #competitionGift').on('click', function () {
        $('#competitionGift').addClass('isActive');
    });
});