// saleBox countdown >>
$(function () {
  if ($('#countDown').length) {
    let time = $('#countDown').attr('data-countDown');
    let countDown = $("#countDown").countdown(time);
    countDown.on('update.countdown', function (event) {
      let totalHours = event.offset.totalDays * 24 + event.offset.hours;
      $(this).html(event.strftime(totalHours + ':%M:%S'));
    });
    countDown.on('finish.countdown', function () {
      $(".discountOfToday").addClass('discountOfToday--soldOut')
    });
  }
});
// << saleBox countdown
