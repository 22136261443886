// basketPreview >>
function showBasketPreview() {
  showOverlay();
  $('body').addClass('open-basketPreview');
}

function hideBasketPreview() {
  hideOverlay();
  $('body').removeClass('open-basketPreview');
}

$(function () {
  $('#basketPreview').on('click', '.basketPreview-open', e => {
    e.preventDefault();
    if ($('body').hasClass('open-basketPreview')) {
      hideBasketPreview();
    } else {
      showBasketPreview();
    }
  });

  $('.overlay').on('click', (e) => {
    if ($('body').hasClass('open-basketPreview')) {
      e.preventDefault();
      hideBasketPreview();
    }
  });
});

// << basketPreview
