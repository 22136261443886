$('*[data-scroll]').on('click', function () {
    var target = $(this).attr('data-scroll');
    var headerHeight = 0;
    scrollWin(target, 1000, -headerHeight);

    if (typeof $(this).data('history') !== 'undefined') {
        window.location.hash = target;
    }

    return false;
});