// Lazy Initialization:
var observerEnabled = 'IntersectionObserver' in window;
var lazyInit = (element, fn) => {
  if (observerEnabled) {
    var observer = new IntersectionObserver((entries) => {
      if (entries.some(({isIntersecting}) => isIntersecting)) {
        observer.disconnect();
        fn();
      }
    });
    observer.observe(element);
  } else {
    fn();
  }
};
